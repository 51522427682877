import {
  getGroupsListSuccess,
  getGroupsListFailure,
} from '../store/groupsReducer';
import axios from 'axios';

export const fetchGroupsList = (user_id) => {
  return function (dispatch) {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/ad_groups/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getGroupsListSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getGroupsListFailure());
      });
  };
};
