import {
  getUsersList,
  getUsersListSuccess,
  getUsersListFailure,
  storeTotalPagesCount,
} from '../store/usersReducer';
import axios from 'axios';

export const fetchUsersList = (user_id) => {
  return function (dispatch) {
    dispatch(getUsersList());

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/ad_users_with_profiles/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getUsersListSuccess(response.data.results));
        dispatch(storeTotalPagesCount(response.data.total_pages));
      })
      .catch(() => {
        dispatch(getUsersListFailure());
      });
  };
};
