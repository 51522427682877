const CREATE_AUTH_KEY = 'CREATE_AUTH_KEY';
const ADD_USER = 'ADD_USER';
const GET_AUTH_KEY = 'GET_AUTH_KEY';
const GET_AUTH_KEY_SUCCESS = 'GET_AUTH_KEY_SUCCESS';
const GET_AUTH_KEY_FAILURE = 'GET_AUTH_KEY_FAILURE';
const GET_USERS_LIST = 'GET_USERS_LIST';
const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';
const USERS_LIST_FILTERED = 'USERS_LIST_FILTERED';
const USERS_LIST_ASSIGNED = 'USERS_LIST_ASSIGNED';
const STORE_TOTAL_PAGES_COUNT = 'STORE_TOTAL_PAGES_COUNT';
const UPDATE_USERS_ON_SCROLL = 'UPDATE_USERS_ON_SCROLL';
const REDUCE_USERS_TO_THEIR_INITIAL_SIZE = 'REDUCE_USERS_TO_THEIR_INITIAL_SIZE';

const initialState = {
  create_auth_key: {},
  auth_key: {},
  new_user: [],
  users_list: [],
  search: [],
  assigned: {
    data: [],
  },
  loading: false,
  error: false,
  totalPagesCount: 1,
  initialUsersSize: 0,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_AUTH_KEY:
      return {
        ...state,
        create_auth_key: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        new_user: action.payload,
      };
    case GET_AUTH_KEY:
      return {
        ...state,
        loading: true,
      };
    case GET_AUTH_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        auth_key: action.payload,
      };
    case GET_AUTH_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        auth_key: {},
      };
    case GET_USERS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        users_list: [],
      };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        users_list: action.payload,
        initialUsersSize: action.payload.length,
      };
    case GET_USERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        users_list: [],
      };
    case USERS_LIST_FILTERED:
      return {
        ...state,
        search: action.payload,
      };
    case USERS_LIST_ASSIGNED:
      return {
        ...state,
        assigned: action.payload,
      };
    case STORE_TOTAL_PAGES_COUNT:
      return {
        ...state,
        totalPagesCount: action.payload,
      };
    case UPDATE_USERS_ON_SCROLL:
      return {
        ...state,
        users_list: [...state.users_list, ...action.payload],
      };
    case REDUCE_USERS_TO_THEIR_INITIAL_SIZE:
      return {
        ...state,
        users_list: state.users_list.slice(0, state.initialUsersSize),
      };
    default:
      return state;
  }
}

export const createAuthKey = (auth_key) => ({
  type: CREATE_AUTH_KEY,
  payload: auth_key,
});

export const getAuthKey = () => ({
  type: GET_AUTH_KEY,
});

export const getAuthKeySuccess = (auth_key) => ({
  type: GET_AUTH_KEY_SUCCESS,
  payload: auth_key,
});

export const getAuthKeyFailure = (error) => ({
  type: GET_AUTH_KEY_FAILURE,
  payload: error,
});

export const addUser = (new_user) => ({
  type: ADD_USER,
  payload: new_user,
});

export const getUsersList = () => ({
  type: GET_USERS_LIST,
});

export const getUsersListSuccess = (users_list) => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: users_list,
});

export const updateUsersOnScroll = (users) => ({
  type: UPDATE_USERS_ON_SCROLL,
  payload: users,
});

export const storeTotalPagesCount = (count) => ({
  type: STORE_TOTAL_PAGES_COUNT,
  payload: count,
});

export const getUsersListFailure = (error) => ({
  type: GET_USERS_LIST_FAILURE,
  payload: error,
});

export const searchUser = (search) => ({
  type: USERS_LIST_FILTERED,
  payload: search,
});

export const assignedUsers = (assigned) => ({
  type: USERS_LIST_ASSIGNED,
  payload: assigned,
});

export const reduceUsersToTheirInitialSize = () => ({
  type: 'REDUCE_USERS_TO_THEIR_INITIAL_SIZE',
});
