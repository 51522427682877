import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';

import Header from '../components/header/Header';
import SubMenu from '../components/sub-menu/SubMenu';
import Footer from './footer/Footer';

import common from '../components/app/App.module.scss';

const ProtectedRoutes = () => {
  return (
    <Grid item className={common['app-right-column']}>
      <Stack sx={{ height: '100%' }}>
        <Header />
        <Stack className={common['wrapper']}>
          <SubMenu />
          <Outlet />
          <Footer />
        </Stack>
      </Stack>
    </Grid>
  );
};

export { ProtectedRoutes };
