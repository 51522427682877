import {
  getAdUsersWithProfilesSuccess,
  getAdUsersWithProfilesFailure,
} from '../store/adUsersWithProfilesReducer';
import axios from 'axios';

export const fetchAdUsersWithProfiles = (user_id) => {
  return function (dispatch) {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/ad_users_with_profiles/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getAdUsersWithProfilesSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getAdUsersWithProfilesFailure());
      });
  };
};
