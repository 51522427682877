import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAuthData } from '../asyncActions/getAuthData';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const cabinetMenu = useSelector((state) => state.cabinetMenu.cabinet_menu);
  const [menu, setMenu] = useState([]);

  const menuAdItem = [
    {
      title: 'Active Directory',
      name: '/',
      url: '/users',
      children: [
        {
          title: 'Домены',
          url: 'domains',
        },
        {
          title: 'Пользователи',
          url: 'users',
        },
        {
          title: 'Коллекции',
          url: 'collections',
        },
      ],
    },
  ];

  let modifyUrlMenu = [];
  let modifyMenu = cabinetMenu;

  useEffect(() => {
    modifyMenu.forEach((item) => {
      modifyUrlMenu.push({
        ...item,
        url: `${process.env.REACT_APP_BASE_URL}${item.url}`,
      });
    });

    setMenu(modifyUrlMenu);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinetMenu]);

  useEffect(() => {
    dispatch(fetchAuthData());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ menu, menuAdItem }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
