import { createTheme } from '@mui/material/styles';

import PTSans400 from '../src/fonts/PT-Sans-400.woff2';
import PTSans700 from '../src/fonts/PT-Sans-700.woff2';
import Roboto400 from '../src/fonts/Roboto-400.woff2';
import Roboto500 from '../src/fonts/Roboto-500.woff2';
import Roboto700 from '../src/fonts/Roboto-700.woff2';

import styles from './components/app/App.module.scss';
import buttonStyles from './components/button/Button.module.scss';
import paper from './styles/paper.module.scss';
import select from './styles/select.module.scss';
import inputLabel from './styles/inputLabel.module.scss';
import formControl from './styles/formControl.module.scss';
import tooltip from './styles/tooltip.module.scss';
import notification from './styles/nofication.module.scss';
import divider from './styles/divider.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#07a9ed',
      light: '#5ccfff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      dark: '#f5f6f8',
      contrastText: '#334d6e',
    },
    warning: {
      main: '#e08d8d',
      light: 'rgba(224, 141, 141, 0.20);',
    },
    error: {
      main: '#ff5050',
      light: '#ff8a65',
    },
    info: {
      main: '#334d6e',
      light: '#a6b1c1',
    },
    white: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: 'PT-Sans-700, PT-Sans-400, Roboto-500, Roboto-400',
    h1: {
      fontFamily: 'PT-Sans-700',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: 0.16,
    },
    h2: {
      fontFamily: 'Roboto-500',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    h3: {
      fontFamily: 'Roboto-700',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    h6: {
      fontFamily: 'PT-Sans-700',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
    },
    body1: {
      fontFamily: 'Roboto-400',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    body2: {
      fontFamily: 'PT-Sans-400',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    subtitle1: {
      fontFamily: 'PT-Sans-700',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    subtitle2: {
      fontFamily: 'PT-Sans-400',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    caption: {
      fontFamily: 'PT-Sans-400',
      fontSize: '12px',
      fontWeight: 400,
      color: '#8892a4',
      lineHeight: '14px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'PT-Sans-400';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('PT-Sans-400'), local('PT-Sans-400.woff2'), url(${PTSans400}) format('woff2');
        }
        @font-face {
          font-family: 'PT-Sans-700';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('PT-Sans-700'), local('PT-Sans-700.woff2'), url(${PTSans700}) format('woff2');
        }
        @font-face {
          font-family: 'Roboto-400';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Roboto-400'), local('Roboto-400.woff2'), url(${Roboto400}) format('woff2');
        }
        @font-face {
          font-family: 'Roboto-500';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Roboto-500'), local('Roboto-500.woff2'), url(${Roboto500}) format('woff2');
        }
        @font-face {
          font-family: 'Roboto-700';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Roboto-700'), local('Roboto-700.woff2'), url(${Roboto700}) format('woff2');
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        classes: {
          root: buttonStyles['MuiButton-root'],
          outlined: buttonStyles['MuiButton-outlined'],
          contained: buttonStyles['MuiButton-contained'],
          sizeLarge: buttonStyles['MuiButton-sizeLarge'],
          sizeSmall: buttonStyles['MuiButton-sizeSmall'],
          disabled: buttonStyles['Mui-disabled'],
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        classes: {
          root: styles['MuiModal-root'],
          paper: styles['MuiPaper-root'],
        },
      },
    },
    MuiModal: {
      defaultProps: {
        classes: {
          backdrop: styles['MuiBackdrop-root'],
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        classes: {
          root: paper['MuiPaper-root'],
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        native: true,
        classes: {
          root: select['MuiInputBase-root'],
          select: select['MuiNativeSelect-select'],
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        classes: { root: inputLabel['MuiFormLabel-root'] },
      },
    },
    MuiFormControl: {
      defaultProps: {
        classes: {
          root: formControl['MuiFormControl-root'],
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        classes: {
          tooltip: tooltip['MuiTooltip-tooltip'],
          arrow: tooltip['MuiTooltip-arrow'],
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        'aria-label': 'alert',
        classes: {
          root: notification['MuiAlert-root'],
          colorSuccess: notification['MuiAlert-colorSuccess'],
          colorError: notification['MuiAlert-colorError'],
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        classes: {
          root: divider['MuiDivider-root'],
        },
      },
    },
  },
});

export default theme;
