import React from 'react';
import { SvgIcon } from '@mui/material';

import common from '../app/App.module.scss';
import styles from './Footer.module.scss';

import { ReactComponent as VKIcon } from '../../icons/vk.svg';

const vkIconPath = (
  <g>
    <rect x="1.5" y="1.5" width="21" height="21" rx="6" fill="#97A1AF" />
    <path
      d="M17.1925 13.7506C17.0309 13.5313 16.9947 13.3094 17.083 13.0846C17.3033 12.4847 20.512 8.67459 19.1753 8.67459C18.2568 8.6868 17.1522 8.67459 16.2716 8.67459C16.1081 8.71439 16.0023 8.80018 15.9361 8.97473C15.4212 10.1621 14.7911 11.6111 13.8676 12.5424C13.7461 12.6403 13.6703 12.6361 13.5319 12.5999C12.8841 11.9179 13.5748 9.58131 13.1809 8.65804C13.1032 8.47731 12.9333 8.39569 12.7594 8.34972C11.8799 8.14012 9.87126 8.26488 9.54353 8.6868C9.44984 8.80742 9.43677 8.87324 9.50441 8.88406C9.81667 8.93333 10.0377 9.05118 10.1678 9.2375C10.4133 9.77802 10.5942 12.6573 9.87126 12.6573C9.14836 12.6573 7.98265 9.8316 7.66241 9.04827C7.57719 8.81241 7.36024 8.67719 7.13165 8.63728L4.99297 8.65363C4.61704 8.65363 4.40694 8.83907 4.54019 9.21268C5.6541 11.9777 8.09173 17.3373 11.8304 17.2031C12.205 17.2031 12.8493 17.3469 13.1418 17.0222C13.5438 16.4889 13.0535 15.5391 13.7428 15.1769C13.9166 15.0844 14.0968 15.162 14.2462 15.2757C15.0129 15.8595 15.4199 16.8842 16.3654 17.1952C16.5317 17.25 16.6773 17.2637 16.8024 17.2363L18.8005 17.2035C19.1715 17.2035 19.5369 17.0384 19.4602 16.5949C19.1689 15.6439 18.0081 14.6662 17.1925 13.7506Z"
      fill="white"
    />
  </g>
);

const Footer = () => {
  const thisYear = new Date().getFullYear();

  return (
    <footer className={styles['footer']}>
      <div className={styles['year']}>
        &copy; {thisYear} SkyDNS - Все права защишены
      </div>
      <div className={styles['footer-social']}>
        <a
          href="https://vk.com/skydns"
          className={styles['vk']}
          rel="noreferrer"
          aria-label="vk"
        >
          <SvgIcon component={VKIcon} />
          {/* <img src={vk} width={24} height={24} alt="vk" /> */}
          <span className={common['offscreen']}>VK</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
