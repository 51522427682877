import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import AuthContext from '../../contexts/authContext';

import styles from './Menu.module.scss';
import headerStyles from '../header/Header.module.scss';
import appStyles from '../app/App.module.scss';

// import MenuIcons from '../../icons/menu-icons.svg';

import { uid } from '../../uid';

const menuIcons = {
  submenu__main_page: 'main-menu',
  submenu__main_settings: 'settings',
  submenu__categories: 'categories',
  ActiveDirectory: 'network',
  submenu__main_lists: 'lists',
  Denylist: 'stop',
  submenu__main_statistics: 'chart-raises',
  submenu__new_statistics: 'chart-raises',
  Partners: 'group',
  submenu__support_main: 'help',
  submenu__link_account: 'account',
};

// TODO: need to figure out how to implement :hover on svg sprites.
// And use svg sprites instead of manually added svg icons
// const MenuIcon = (props) => {
//   return (
//     <svg className="menu-icon-container">
//       <use href={`${MenuIcons}#${props.id}`} className="menu-icon" />
//     </svg>
//   );
// };

const Menu = () => {
  const { menu } = useContext(AuthContext);
  const isAuthData = useSelector((state) => state.auth.auth);

  return (
    <nav className={styles['menu']}>
      {menu.map((item) => {
        const icon = menuIcons[item.css_class];
        const cls = styles['menu-icon'] + ' ' + styles[icon];

        return (
          <NavLink
            key={uid()}
            to={item.url}
            className={
              item.url.includes('agent_ad')
                ? styles['menu-item-active']
                : styles['menu-item']
            }
          >
            {/* <MenuIcon id={item.css_class} /> */}
            <div role="img" className={cls} />
            <Typography variant="body2" component="span" sx={{ pl: '12px' }}>
              {item.title}
            </Typography>
          </NavLink>
        );
      })}

      <div className={styles['mobile-logout']}>
        <span className={headerStyles['header-controls-account']}>
          {isAuthData.username}
        </span>
        {isAuthData.is_authenticated && (
          <a
            href={`${process.env.REACT_APP_BASE_URL}/auth/logout/`}
            className={headerStyles['header-controls-logout']}
          >
            <span className={appStyles['offscreen']}>Logout</span>
          </a>
        )}
      </div>
    </nav>
  );
};

export default Menu;
