import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';
// import jsCookie from "js-cookie";

import Navbar from '../navbar/Navbar';
import { ProtectedRoutes } from '../ProtectedRoutes';
import Users from '../users/Users';
import Collections from '../collections/Collections';
import CollectionsCreate from '../collections/CollectionsCreate';
import CollectionsEditForm from '../collections/CollectionsEditForm';
import Domains from '../domains/Domains';
import { fetchAuthKeyAd } from '../../asyncActions/getAuthKeyAd';
import { fetchCabinetMenu } from '../../asyncActions/getCabinetMenu';
import { fetchProfilesList } from '../../asyncActions/getProfilesList';
import { fetchDomainsList } from '../../asyncActions/getDomainsList';
import { fetchUsersList } from '../../asyncActions/getUsersList';
import { fetchAdUsersWithProfiles } from '../../asyncActions/getAdUsersWithProfiles';
import { fetchGroupsList } from '../../asyncActions/getGroupsList';
import { fetchCollectionsList } from '../../asyncActions/getCollectionsList';
import AppBar from '../appbar/AppBar';

import styles from './App.module.scss';

const App = () => {
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);
  // const cabinetMenu = useSelector(state => state.cabinetMenu.cabinet_menu);
  // const isAuthKeyAd = useSelector(state => state.users.auth_key);
  const domainData = useSelector((state) => state.domains.new_domain);
  const removeDomainData = useSelector((state) => state.domains.remove_domain);
  const updateDomainData = useSelector((state) => state.domains.update_domain);
  const collectionData = useSelector(
    (state) => state.collections.new_collection
  );
  const collectionPriority = useSelector(
    (state) => state.collections.collection_priority
  );
  const updateCollection = useSelector(
    (state) => state.collections.collection_update
  );
  const removeCollectionData = useSelector(
    (state) => state.collections.remove_collection
  );

  // csrf token is not used but will be, I guess, so I excluded it for while
  // const csrftoken = jsCookie.get('csrftoken');

  //get cabinet menu, profiles list, auth key active directory, users, users group lists
  useEffect(() => {
    if (isAuthData.is_authenticated) {
      dispatch(fetchCabinetMenu(isAuthData.user_id));
      dispatch(fetchProfilesList(isAuthData.user_id));
      dispatch(fetchAuthKeyAd(isAuthData.user_id));
      dispatch(fetchUsersList(isAuthData.user_id));
      dispatch(fetchAdUsersWithProfiles(isAuthData.user_id));
      dispatch(fetchGroupsList(isAuthData.user_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthData]);

  //get dmains list
  useEffect(() => {
    isAuthData.is_authenticated &&
      dispatch(fetchDomainsList(isAuthData.user_id));
  }, [isAuthData, domainData, removeDomainData, updateDomainData, dispatch]);

  //get collections list
  useEffect(() => {
    isAuthData.is_authenticated &&
      dispatch(fetchCollectionsList(isAuthData.user_id));
  }, [
    isAuthData,
    collectionData,
    updateCollection,
    removeCollectionData,
    collectionPriority,
    dispatch,
  ]);

  return (
    <Grid container className={styles['app']}>
      {isAuthData.is_authenticated && (
        <>
          <AppBar />
          <Navbar />
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route index path="/" element={<Navigate to="/users" />} />
              <Route path="/users" element={<Users />} />
              <Route path="/collections" element={<Collections />} />
              <Route
                path="/collections/create"
                element={<CollectionsCreate />}
              />
              <Route
                path="/collections/:id"
                element={<CollectionsEditForm />}
              />
              <Route path="/domains" element={<Domains />} />
              <Route path="*" element={<p>404</p>} />
            </Route>
          </Routes>
        </>
      )}
    </Grid>
  );
};

export default App;
