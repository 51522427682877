import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, Button } from '@mui/material';

import Logout from '../../icons/logout';
import { ReactComponent as GearIcon } from '../../icons/gear.svg';

import styles from './Header.module.scss';

const CustomButton = ({ href, startIcon, children }) => (
  <Button
    startIcon={startIcon}
    href={href}
    LinkComponent="a"
    sx={{
      pt: '0 !important',
      pr: '0 !important',
      pb: '0 !important',
      color: 'info.main',
      fontSize: '14px !important',
      fontFamily: 'PT-Sans-400',
      fontWeight: 400,
    }}
  >
    {children}
  </Button>
);

const Header = () => {
  const isAuthData = useSelector((state) => state.auth.auth);

  return (
    <header className={styles['header']}>
      <Grid container sx={{ gap: '12px', width: 'auto' }}>
        <Typography variant="body2" component="span" color="info.light">
          Ваш IP-адрес:{' '}
          <Typography variant="subtitle1" component="span" color="info.main">
            94.50.147.65
          </Typography>
        </Typography>
        <Typography variant="body2" component="span" color="info.light">
          Наш DNS-сервер:{' '}
          <Typography variant="subtitle1" component="span" color="info.main">
            193.58.251.251
          </Typography>
        </Typography>
      </Grid>

      <div className={styles['header-controls']}>
        {isAuthData.permissions.is_staff && (
          <CustomButton
            href={`${process.env.REACT_APP_BASE_URL}/you-were-never-here/`}
            startIcon={<GearIcon />}
          >
            Admin interface
          </CustomButton>
        )}

        {isAuthData.is_authenticated && (
          <CustomButton
            href={`${process.env.REACT_APP_BASE_URL}/auth/logout/`}
            startIcon={<Logout />}
          >
            Выйти
          </CustomButton>
        )}
      </div>
    </header>
  );
};

export default Header;
